
import { defineComponent } from "vue";
import Footer from "./views/Layout/Footer.vue";
import Navbar from "./views/Layout/Navbar/index.vue";

export default defineComponent({
  name: "App",

  components: {
    Navbar,
    Footer
  }
});
