
import { defineComponent } from "vue";

export default defineComponent({
  name: "VImage",

  props: {
    path: {
      type: String,
      required: true
    }
  },

  computed: {
    image (): string {
      if (this.path.indexOf("@") !== -1) {
        return this.path;
      }
      return `/resources/${this.path}`;
    }
  }
});
