
import { defineComponent } from "vue";
import { enUS, zhCN } from "@/plugins/i18n";

export default defineComponent({
  name: "HomeFooter",

  i18n: {
    messages: {
      [zhCN]: {
        telephone: "客服热线",
        address: "地址：上海市奉贤区解放东路1018弄1号楼1008室",
        beian: "沪ICP备2021032022号-1"
      },
      [enUS]: {
        telephone: "tel",
        address: "Address: Room 1008, Building 1, Lane 1018 of JieFang East Road, FengXian District, ShangHai",
        beian: "沪ICP备2021032022号-1"
      }
    }
  },
});
